<script>
import { mapGetters } from 'vuex'

import { GET_TEMPLATE, ISSUE_USER_CREDENTIAL, UPLOAD_FILE } from '../../store/actions'

import TemplateCanvas from '../../components/TemplateEditor/components/Canvas/Canvas.vue'

import MessageModal from '../../components/TemplateEditor/modals/MessageModal'
import { capture } from '../../plugins/snapshot'

export default {
  name: 'IssueCredential',
  title: 'pageTitle.issueCredential',
  components: {
    MessageModal,
    TemplateCanvas,
  },
  emits: ['close'],
  data() {
    return {
      // Data to delete
      customTemplate: { 'wallid.io': true },

      currentColor: null,

      editable: true,
      backTemplate: null,
      frontTemplate: [],

      tableValues: [],
      tableLine: [],
      tables: [],
      logos: [],
      sigs: [],

      // Currently used
      /** template */
      customTemplateName: '',
      templateName: '',
      currentLayout: '',

      tid: '',
      attributesMenu: {
      },

      step: 0,
      isLoaded: false,
      imageLoading: false,

      email: null,
      errorMail: null,

      reload: true,
      sending: false,

      templateValuesFront: [],
      templateValuesBack: [],
      templateCopy: null,
      photoURLBase64: null,

      buttonText: this.$t('button.next'),
      showSuccessModal: false,
      pages: ['#page'],

      currentFonts: new Set(),

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.[^\n\r@\u2028\u2029]*@.+\..+/.test(v) || this.$t('issue.emailError'),
      ],

      issueCredentialWidth: 0,
    }
  },
  computed: {
    ...mapGetters(['adminEmail', 'cid', 'caName']),
    canvasStyle() {
      return 'max-height: 60vh;'
    },
    components: {
      get() {
        return this.$store.getters.components
      },
      set(value) {
        this.$store.commit('setComponents', value)
      },
    },
  },
  watch: {},

  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    console.log('beforeUnmount() tid: ', this.tid)
    window.onbeforeunload = null
  },
  created() {
    const tid = this.$route.params.tid
    this.tid = tid
    this.$store.dispatch(GET_TEMPLATE, { tid }).then((data) => {
      console.log('GET_TEMPLATE', data)
      this.initialLoad(data)
    })
  },
  mounted() {
    window.onbeforeunload = function () {
      return 'Are you sure you want to close the window?'
    }
  },
  methods: {
    uploadPhoto(file, folder) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD_FILE, { file, folder })
          .then((url) => {
            resolve(url)
          })
          .catch((err) => {
            this.logError('uploadPhotos', err)
            reject(err)
          })
      })
    },
    uploadImage(field, index) {
      this.debug('uploadImage function', field, index)
      this.$log.debug(this.$refs)
      this.$refs.uploadImage[index].click()
    },
    async newImage(event, field) {
      this.imageLoading = true
      field.value = await this.$store
        .dispatch(UPLOAD_FILE, {
          file: event.target.files[0],
          folder: `${this.tid}`,
        })
        .catch((err) => {
          console.error(err)
          throw err
        })
      this.imageLoading = false

      this.replaceAttributeImage(field)
    },
    async initialLoad(template = {}) {
      this.debug('Call GET_TEMPLATE action')
      // const template = await this.$store.dispatch(GET_TEMPLATE, {
      //   tid: this.$route.params.tid,
      // })
      const frontendProps = template.frontend_props || {}
      const templateItens = template.templateItens || []
      const components = frontendProps.components || []
      const customTemplateName = frontendProps.customTemplateName || 'templateEditor'
      await this.$store.dispatch('setTemplate', frontendProps)

      this.debug('IssueCertificate: ', customTemplateName === 'templateEditor')
      if (customTemplateName === 'templateEditor' && templateItens.length > 0) {
        this.debug('createTemplateItems: ')

        this.createTemplateItems(templateItens)

        this.$store.dispatch('enableBackSide', false)

        if (frontendProps.backgroundBack) {
          if (this.pages.length === 1) { this.pages.push('#back') }

          this.$store.dispatch('enableBackSide', true)
        }

        // copy template values to reset when beforeDestroy() is called
        this.templateCopy = JSON.parse(JSON.stringify(frontendProps))

        await this.splitBaseTextIntoArray(components)

        this.$store.commit('setActiveComponent', '')
        this.frontendProps = frontendProps
        this.components = components
        this.customTemplateName = customTemplateName
        this.templateName = template.name
        this.currentLayout = frontendProps.currentLayout
        await this.$forceUpdate()
        console.log('issueCredentialWidth', this.$refs.formTemplate, this.$refs.formTemplate.offsetWidth, this.$refs.formTemplate.clientWidth, this.$refs.formTemplate.scrollWidth, this.$refs.formTemplate.scrollWidth - this.$refs.formTemplate.clientWidth)
        this.issueCredentialWidth = this.$refs.formTemplate.clientWidth - 300
        this.isLoaded = true
      }
    },
    async splitBaseTextIntoArray(components) {
      console.log('splitBaseTextIntoArray', components)
      components.forEach(async (component) => {
        if (component.templateSide === 'back') {
          if (this.pages.length === 1) { this.pages.push('#back') }

          this.$store.dispatch('enableBackSide', true)
        }
        if (
          component.type === 'text'
          || (component.type === 'image'
          && component.dynamicImage)
        ) {
          // this.$log.debug(component);
          // .filter(function(el) {
          //   return el;
          // });
          //  /((?:\[[A-Z0-9_]+\]))|([a-zA-Z0-9]+)/g
          if (component.fontFamily) {
            this.currentFonts.add(component.fontFamily)
          }

          component.baseText = component.text.match(
            // eslint-disable-next-line regexp/no-obscure-range
            /(\[[A-Z0-9_]+\])|([a-zA-Z0-9!@#$&()\\-`.+,/"]*)/g,
          )
          component.originalText = component.text.match(
            // eslint-disable-next-line regexp/no-obscure-range
            /(\[[A-Z0-9_]+\])|([a-zA-Z0-9!@#$&()\\-`.+,/"]*)/g,
          )
        }
        if (component.type === 'image') {
          console.log('components[i]', component)
          component.src = await this.toDataURL(
            component.src,
          )
        }
      })

      this.templateValuesFront.forEach((item) => {
        // this.$log.debug(item);
        item.indexArray = this.findAttr(item, components)
      })
      this.templateValuesBack.forEach((item) => {
        // this.$log.debug(item);

        item.indexArray = this.findAttr(item, components)
      })
      this.templateValuesFront.sort(this.compareAttr)
      this.templateValuesBack.sort(this.compareAttr)

      this.$log.debug('templateValuesFront', this.templateValuesFront)
      this.$log.debug('templateValuesBack', this.templateValuesBack)
    },

    compareAttr(a, b) {
      if (a.attr < b.attr) { return -1 }

      if (a.attr > b.attr) { return 1 }

      return 0
    },
    findAttr(item, components) {
      let indexAttr, indexComponent

      components.some((component, i) => {
        if (
          (component.type === 'text'
          && component.baseText
          && component.baseText.length > 0)
          || (component.type === 'image'
          && component.dynamicImage)
        ) {
          indexAttr = component.baseText.findIndex((el) => {
            return el.includes(`[${item.attr}]`)
          })
          // this.$log.debug(indexAttr);
          if (indexAttr > -1) {
            indexComponent = i
            return true
          }
          return false
        }
        return false
      })
      return { indexComponent, indexAttr }
    },
    replaceAttributeImage(component) {
      this.$log.debug('START REPLACE FUNCTION')
      this.$log.debug(component)
      const indexComponent = component.indexArray.indexComponent

      this.components[indexComponent].src = component.value
      this.$forceUpdate()
    },
    replaceAttributeText(component) {
      this.$log.debug('START REPLACE FUNCTION')
      this.$log.debug(component)
      if (this.customTemplateName === 'templateEditor') {
        const indexAttr = component.indexArray.indexAttr
        const indexComponent = component.indexArray.indexComponent

        this.$log.debug(this.components[indexComponent])
        // Get Base text
        const baseText = this.components[indexComponent].baseText

        if (component.value) {
          baseText[indexAttr] = component.value
          this.$log.debug(baseText)
        }
        else {
          baseText[indexAttr]
            = this.components[indexComponent].originalText[indexAttr]
        }
        if (this.components[indexComponent].maxCharacters) {
          component.maxCharacters
            = this.components[indexComponent].maxCharacters
        }

        const newValue = baseText.join(' ')
        // this.$log.debug(newValue);
        this.components[indexComponent].text = newValue
        this.$forceUpdate()
      }
    },

    createTemplateItems(templateItens) {
      templateItens.forEach((e) => {
        if (e.order === 0) {
          this.templateValuesFront.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          })
        }
        else if (e.order === 1) {
          this.templateValuesBack.push({
            attr: e.attr,
            value: null,
            error: false,
            type: e.type,
            temp_item_id: e._id,
            isPublic: e.isPublic,
            isMandatory: e.isMandatory,
          })
        }
      })
    },

    async toDataURL(url) {
      console.log('toDataURL', url)
      if (url?.startsWith('data:image')) { return url }

      return await new Promise((resolve) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.onerror = function () {
          resolve(undefined)
          console.error('** An error occurred during the XMLHttpRequest')
        }
        xhr.open('GET', `${url}?_=${Date.now()}`, true)
        xhr.responseType = 'blob'
        xhr.send()
      })
    },
    compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const valA = a.order
      const valB = b.order

      let comparison = 0
      if (valA > valB) { comparison = 1 }
      else if (valA < valB) { comparison = -1 }

      return comparison
    },
    removeItem(index) {
      this.tableValues.splice(index, 1)
    },
    addTableLine(indexT) {
      this.$log.debug(this.tableLine[indexT])
      const el = {}
      let error = false
      this.tableLine[indexT].forEach((e, index) => {
        if (e.value) {
          this.tableLine[indexT][index].error = false
          el[e.input] = e.value
          e.value = null
        }
        else {
          this.tableLine[indexT][index].error = true
          error = true
        }
      })
      if (!error) { this.tableValues.push(el) }
    },
    clearErrorTable(indexT, indexL) {
      this.tableLine[indexT][indexL].error = false
    },
    clearError(index) {
      if (
        this.templateValuesFront[index]
        && this.templateValuesFront[index].value
      ) { this.templateValuesFront[index].error = false }
      else if (
        this.templateValuesBack[index]
        && this.templateValuesBack[index].value
      ) { this.templateValuesBack[index].error = false }
      else { this.templateValuesFront[index].error = true }
    },
    checkForm() {
      this.debug('Check form')
      let error = false
      this.templateValuesFront.forEach((e) => {
        if ((e.isMandatory === 'true' || e.isMandatory === true) && !e.value) {
          e.error = true
          error = true
        }
        else if (['DATEOFBIRTH', 'EXPIRYDATE'].includes(e.attr)) {
          const date = new Date(e.value)
          if (date.toString() === 'Invalid Date') {
            e.error = true
            error = true
          }
          else {
            e.error = false
          }
        }
        else {
          e.error = false
        }
      })
      this.templateValuesBack.forEach((e) => {
        if ((e.isMandatory === 'true' || e.isMandatory === true) && !e.value) {
          e.error = true
          error = true
        }
        else {
          e.error = false
        }
      })
      if (this.tables && this.tables.length > 0 && this.tableValues.length === 0) { error = true }

      return error
    },
    async createData() {
      let templateValuesFrontMapped = this.templateValuesFront.map((e) => {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        }
        // }
      })

      await Promise.all(templateValuesFrontMapped).then((results) => {
        templateValuesFrontMapped = results
      })
      let templateValuesBackMapped = this.templateValuesBack.map((e) => {
        return {
          value: e.value,
          temp_item_id: e.temp_item_id,
          isPublic: e.isPublic,
        }
        // }
      })

      await Promise.all(templateValuesBackMapped).then((results) => {
        templateValuesBackMapped = results
      })
      this.data = templateValuesFrontMapped.concat(templateValuesBackMapped)
      if (
        this.tables
        && this.tables.length > 0
        && this.tableValues.length > 0
      ) {
        this.data.push({
          values: this.tableValues,
          temp_item_id: this.tables[0].item_id,
          isPublic: true,
        })
      }
      this.$log.debug('Generated Data: ', this.data)
    },
    close() {
      this.$emit('close')
    },
    back() {
      this.step = 0
      this.buttonText = this.$t('button.next')
      this.email = null
      this.editable = true
    },
    async takeTemplateSnapshot() {
      this.reload = false
      await this.$nextTick()
      this.reload = true
      await this.$nextTick()

      const snapshotElementId = 'template-canvas'
      const element = document.getElementById(snapshotElementId)

      const capturedImage = await capture(element)
      this.debug('takeTemplateSnapshot:imgArray', capturedImage)
      const imgArray = await this.$store
        .dispatch(UPLOAD_FILE, {
          file: capturedImage,
          folder: `${this.cid}`,
        })
        .catch((err) => {
          console.error(err)
        })
      this.debug('UPLOAD_FILE:result', imgArray)

      return [imgArray]
    },
    async nextStep() {
      try {
        switch (this.step) {
          case 0:
            if (this.checkForm()) {
              this.sending = false
              return
            }
            await this.createData()
            this.step += 1
            this.editable = false
            this.sending = false
            // await this.takeTemplateSnapshot()
            break
          case 1:
            this.debug('Emit cert')
            this.sending = true
            this.errorMail = null

            if (!this.email) {
              this.errorMail = this.$t('issue.emailError')
            }
            try {
              const imageArray = await this.takeTemplateSnapshot()
              await this.$store
                .dispatch(ISSUE_USER_CREDENTIAL, {
                  tid: this.tid,
                  email: this.email,
                  data: this.data,
                  imageArray,
                })
              this.showSuccessModal = true
            }
            catch (error) {
              this.sending = false
              console.error(error)
            }

            break

          default:
            break
        }
      }
      catch (error) {
        console.error(error)
        // } finally {
        //   this.sending = false;
      }
    },
  },

}
</script>

<template>
  <v-container class="issue-cred px-0">
    <v-row>
      <v-col cols="6" class="">
        <h2 class="title_header pr-4">
          {{ $t('issue.title') }}
        </h2>
        <div class="steps">
          {{ $t('step[0]') }} {{ step + 1 }} {{ $t('step[1]') }} 2
        </div>
      </v-col>
      <v-col cols="6" class="text-right pr-0">
        <v-btn v-if="step === 1" class="back mr-4" @click="back()">
          {{
            $t('button.back')
          }}
        </v-btn>
        <v-btn class="next" :loading="sending" @click="nextStep()">
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card flat class="pa-0 mt-6 issue-form-wrapper">
      <v-row>
        <v-col cols="12" md="12" lg="5" class="pt-0">
          <v-stepper v-model="step" elevation="0">
            <v-stepper-items>
              <v-stepper-content step="0" class="pa-3">
                <v-container class="" style="max-height: 55vh; overflow-y: auto">
                  <v-row>
                    <v-col
                      v-if="templateValuesBack.length > 0"
                      cols="12"
                      class=""
                    >
                      <p class="mb-0 side-header">
                        {{ $t('createCertModal.front') }}
                      </p>
                    </v-col>
                    <v-col
                      v-for="(field, index) in templateValuesFront"
                      :key="field._id"
                      cols="12"
                      class="input-field pb-0"
                    >
                      <label class="">
                        {{
                          field.attr === 'IMAGE' && field.type === 'image'
                            ? $t('issue.labelUpload')
                            : field.attr
                        }}
                        <span
                          v-if="field.isMandatory === 'true'"
                          style="color: #e95e5e"
                        >
                          *
                        </span>
                      </label>

                      <v-menu
                        v-if="field.inputType === 'date'"
                        v-model="attributesMenu[field.attr]"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            color="#009fb1"
                            readonly
                            :value="templateValuesFront[index].value"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="templateValuesFront[index].value"
                          :locale="$i18n.locale"
                          no-title
                          @input="
                            attributesMenu[field.attr] = false;
                            replaceAttributeText(field);
                          "
                        />
                      </v-menu>

                      <!-- text inputs -->
                      <v-text-field
                        v-else-if="field.type !== 'image'"
                        v-model="templateValuesFront[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        :maxlength="field.maxCharacters"
                        :counter="field.maxCharacters"
                        :error="templateValuesFront[index].error"
                        @input="
                          clearError(index);
                          replaceAttributeText(field);
                        "
                      />
                      <v-btn
                        v-show="field.type === 'image'"
                        text
                        :loading="imageLoading"
                        class="advance-btn upload my-5"
                        @click="uploadImage(field, field.indexArray.indexAttr)"
                      >
                        {{ $t('issue.buttonUpload') }}
                      </v-btn>

                      <input
                        v-if="field.type === 'image'"
                        ref="uploadImage"
                        type="file"
                        style="display: none"
                        accept="image/*"
                        @change="newImage($event, field)"
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="templateValuesBack.length > 0"
                      cols="12"
                      class=""
                    >
                      <p class="mb-0 side-header">
                        {{ $t('createCertModal.back') }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="(field, index) in templateValuesBack"
                      :key="field._id"
                      cols="12"
                      class="input-field pb-0"
                    >
                      <label class="">{{ field.attr }}

                        <span
                          v-if="field.isMandatory === 'true'"
                          style="color: #e95e5e"
                        >
                          *
                        </span>
                      </label>
                      <v-text-field
                        v-model="templateValuesBack[index].value"
                        class="mt-2"
                        :type="field.type"
                        flat
                        solo
                        :error="templateValuesBack[index].error"
                        @input="
                          clearError(index);
                          replaceAttributeText(field);
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
              <v-stepper-content step="1" class="pa-3">
                <v-container class="">
                  <v-row>
                    <v-col
                      cols="12"
                      class="input-field pb-0"
                      style="padding-right: 10px"
                    >
                      <label class=""> {{ $t('issue.emailField') }}</label>
                      <v-text-field
                        v-model="email"
                        class="mt-2"
                        flat
                        persistent-hint
                        :rules="emailRules"
                        :hint="$t('issue.emailHint')"
                        :error-messages="errorMail"
                        solo
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
        <v-col cols="12" lg="7" class="pt-0 template-background">
          <v-row>
            <v-col
              v-show="customTemplateName === 'templateEditor'"
              ref="formTemplate"
              cols="12"
              class="pt-6"
            >
              <TemplateCanvas
                v-if=" isLoaded && (reload || currentLayout === 'Card')"
                :editable="false"
                :width="issueCredentialWidth"
                :style="canvasStyle"
              />

              <!-- create a div that is behind all the html and is full width and height -->
              <div
                style="position: absolute; top: 0; left: 0;  z-index: -1; opacity: 0; overflow: hidden;"
              >
                <TemplateCanvas
                  v-if=" isLoaded && (reload || currentLayout === 'Card')"
                  :editable="false"
                  :width="1420"
                  :snapshot="true"
                />
              </div>
            </v-col>
            <!-- <v-col
              v-if="customTemplateName !== 'templateEditor' && isLoaded"
              cols="12"
              class="static_image pt-0"
            >
              <CustomCard
                :front-template="frontTemplate"
                :back-template="backTemplate"
                :tableValues="tableValues"
                :template-values="templateValuesFront"
                :ca-name="caName"
                :credential-name="templateName"
                :url-photo="photoURLBase64"
                :editable="editable"
                :frontend-props="frontendProps"
                @remove-item="removeItem"
              />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <MessageModal
      v-if="showSuccessModal"
      message="email"
      @close="$router.go(-1)"
    />
  </v-container>
</template>

<style lang="scss">
.category-select{
  .v-input__slot{
    border: 1px solid #009fb1;
    border-radius: 3px;
  }
}

// Styles for canvas

.issue-form-wrapper {
  // max-height: 70vh;
}
.page {
  textarea {
    overflow: hidden;
  }
}

.issue-cred {
  .template-background {
    background-color: #e2e2e2;
  }
  .passepartout {
    padding-top: 0.8rem;
  }
  .v-stepper {
    box-shadow: none;
  }
  .confirm.v-btn {
    background-color: #00808e !important ;
  }
  .modal-body.scroll {
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .static_image {
    height: 265px;
    max-width: 376px;
    background: white;
    position: relative;
    border-radius: 18px;

    margin: 50px auto;

    .id-card {
      position: absolute;
      margin-left: -12px;
      margin-right: -12px;
    }
  }
  .input-field {
    display: flex;
    flex-direction: column;
    .advance-btn.upload {
      max-width: 150px;
      svg {
        margin-top: 0 !important;
      }
    }
    .v-input--radio-group {
      .v-input__slot {
        border: none !important;
      }
    }
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
  div.steps {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--teal-blue);
  }
  h2.title_header,
  div.steps {
    display: inline;
  }
  .side-header {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    text-transform: uppercase;
  }
}
</style>
